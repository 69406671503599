import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        people: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_people" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        wine_tasting: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_wine_tasting" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Not your average wine club</h2>
              <p>
                Access to master sommeliers worldwide. One case of wine per month.
                Visit the most beautiful vineyards around the globe. These are all
                things you do not get when you join Jaja Wine Club.
                What will you receive? A single, monthly wine recommendation.
              </p>
            </div>
            <Art>
              <Img fluid={data.people.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.wine_tasting.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Only the world's best wines are worthy</h2>
              <p>
                At Jaja Wine Club, we believe life is too short to drink bad wine.
                So our award-winning* team of unqualified sommeliers spends more than
                a few minutes every month to seek out the highest quality wines.</p>
                <br/>
                <p>The result? A monthly wine... every time.</p>
                <br/>
                <Asterix>*this one time we got a participation medal in elementary school track &amp; field</Asterix>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 6rem 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Asterix = styled.small`
font-style: italic;
color: ${props => props.theme.color.black.lighter}
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
