import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const LOGOS = [
  {
    logo: 'vivinologo.png',
    link: 'https://vivino.com',
  },
  {
    logo: 'instagram.png',
    link: 'https://instagram.com',
  },
  {
    logo: 'tipple.png',
    link: 'https://trytipple.com',
  },
  {
    logo: 'google.png',
    link: 'https://google.com',
  },
];

const UsedBy = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "logos" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "red-glass" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="brands" accent>
        <StyledContainer>
          <StyledTitle>As seen on</StyledTitle>
          <LogoGrid>
            {LOGOS.map(({ logo, link }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === logo
              ).node;
              
              return (
              <ExternalLink key={link} href={link}>
                <Img fluid={img.childImageSharp.fluid} alt={logo} />
              </ExternalLink>
            )})}
          </LogoGrid>
        </StyledContainer>
      </Section>
    )}
  />
);

const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: min-content;
  grid-gap: 32px;
  align-items: center;
  width: 90%;
  margin-top: 72px;

  a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledTitle = styled.h1`
  color: ${props => props.theme.color.white.regular};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

export default UsedBy;
