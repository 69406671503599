import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [
  {
    title: 'How Does It Work?',
    content: () => (
      <>
        Once your membership has been approved, your wine journey begins! You will have
        VIP access to a tight-knit community of a few random amateur wine connesieurs.
        Each month, Jaja Wine Club will feature a single bottle of wine, which members
        will have the privlege of buying for themselves, drinking alone at home, and
        discussing their tasting experience with anyone willing to listen.<br/><br/>We are
        open to recommendations from the member community, message us today <ExternalLink href="https://www.instagram.com/jajawine/">@jajawine</ExternalLink>
      </>
    ),
  },
  {
    title: 'How Do I Sign Up?',
    content: () => (
      <>
        WOW! We are SO grateful, shocked, and overwhelmed, but most importantly,
        we are BEYOND humbled by the overwhelming response to the launch of Jaja Wine Club.
        Memberships are now completely sold out until the Spring of 2021. 
        If you'd like to be placed on the waitlist, please DM us
        on Instagram <ExternalLink href="https://www.instagram.com/jajawine/">@jajawine</ExternalLink>! 
      </>
    ),
  },
  {
    title: 'Do I Have To Be 19 Years Old To Join?',
    content: () => (
      <>
        Yes! We have zero tolerance and a distaste for any individuals who choose to drink
        alcohol below the legal drinking age in their province or country.
        To join Jaja Wine Club, you must have acquired at least 6,935 days
        of life on planet earth and display two pieces of ID (one government issued
        picture ID and one ID which includes your full name) and a signature, upon
        acceptance into the club. Once our VIP Manager has confirmed your age and
        identity, it's time to party!
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
