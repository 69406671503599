import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from "react-slick";

import { Section, Container } from '@components/global';

const WINES = [
  {
    name: '2019 Hester Creek Rosé',
    image: 'may_hester-creek-rose.jpg',
    month: 'May',
  },
  {
    name: '2019 Beaufort Ortega',
    image: 'june.jpg',
    month: 'June',
  },
  {
    name: 'Coming soon..',
    image: 'july.jpg',
    month: 'July',
  },
  {
    name: 'Coming soon..',
    image: 'august.jpg',
    month: 'August',
  },
];

const settings = {
  className: "center",
  infinite: false,
  centerPadding: "24px",
  slidesToShow: 3.25,
  swipeToSlide: true,
};

const Wines = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "wines" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="wines" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <h1>The Wines</h1>
          <Subhead>Browse our selection of carefully curated wines,<br/>hand-picked by completely unqualified sommeliers from across Canada</Subhead>
          <WineGrid {...settings}>
            {WINES.map(({ name, image, month }) => {
              const img = data.allFile.edges.find(
                ({ node }) => node.relativePath === image
              ).node;

              return (
                <Card key={name}>
                  <Img fluid={img.childImageSharp.fluid} alt={name} />
                  <Title>{name}</Title>
                  <Subtitle>{month}</Subtitle>
                </Card>
              );
            })}
          </WineGrid>
        </Container>
      </Section>
    )}
  />
);

const WineGrid = styled(Slider)`
  margin-top: 2rem;

  .slick-list {
    margin-left: 16px -16px;
    padding-top: 12px;
    overflow: hidden
  }

  .slick-slide>div {padding: 0 16px;}

  .slick-center {
    transform: scale(1.1);
  }
`;

const Subhead = styled.h3`
  color: ${props => props.theme.color.black.light};
`;

const Card = styled.div`
  margin-right: 32px;
  cursor: pointer;

  transition: transform .15s cubic-bezier(.215,.61,.355,1),-webkit-transform .15s cubic-bezier(.215,.61,.355,1);

  &:hover {
    transform: translateY(-12px);
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

export default Wines;
